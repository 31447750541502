import {Role, RoleType} from '@hconnect/apiclient'
import jwtDecode from 'jwt-decode'
import {useQuery} from 'react-query'

import {loginStorage} from '../Api/Api'

import {
  getRequestPathData,
  hasOnlyQCSupervisorRole,
  hasSalesAgentRole,
  hasBusinessOwnerRole,
  hasBusinessOwnerRoleCustomer,
  hasQCSupervisorRole
} from './utils'

const fetchRoles = ({queryKey}) => {
  const [endpoint, {userId}] = queryKey

  return getRequestPathData<Role[]>(`${endpoint}?userId=${userId}`)
}

export const useRoles = () => {
  const {accessToken} = loginStorage.getToken()
  if (!accessToken) {
    console.error('no access token')
  }
  const {user_id: userId} = jwtDecode<{user_id: string}>(accessToken as string)
  return useQuery(['roles', {userId}], fetchRoles)
}

export const filterForRoleType = (roles: Role[] | undefined, roleType: string) =>
  roles?.filter((role) => role.roleType === roleType) ?? []

export const filterForCountryId = (roles: Role[], selectedCountryId?: string) =>
  selectedCountryId ? roles.filter((role) => role.dataScope.countryId === selectedCountryId) : roles

export const extractIdsFromRole = (
  salesAgentRoles: Role[],
  businessOwnerRoles: Role[],
  attribute: string
) => {
  const idsFromSalesAgent =
    salesAgentRoles?.flatMap((role) => role.dataScope[attribute] ?? []) ?? []

  const idsFromBusinessOwner =
    businessOwnerRoles?.flatMap((role) => role.dataScope[attribute] ?? []) ?? []

  return [...idsFromBusinessOwner, ...idsFromSalesAgent] as string[]
}

export const useRolesLogic = (selectedCountryId?: string) => {
  const rolesQueryInfo = useRoles()

  const salesAgentRoles = filterForRoleType(rolesQueryInfo.data, 'SALES_AGENT')

  const filteredSalesAgentRoles = filterForCountryId(salesAgentRoles, selectedCountryId)

  const businessOwnerRoles = filterForRoleType(rolesQueryInfo.data, 'BUSINESS_OWNER')

  const filteredBusinessOwnerRoles = filterForCountryId(businessOwnerRoles, selectedCountryId)

  const countriesOfRoles = [
    ...new Set(rolesQueryInfo.data?.map((role) => role.dataScope.countryId))
  ]

  const salesAgentDataScopes = salesAgentRoles.map((role) => role.dataScope)[0]

  const customerIdsFromRole = extractIdsFromRole(
    filteredSalesAgentRoles,
    filteredBusinessOwnerRoles,
    'customerIds'
  )

  const payerIdsFromRole = extractIdsFromRole(
    filteredSalesAgentRoles,
    filteredBusinessOwnerRoles,
    'payerIds'
  )

  const hasCustomerAssignments = customerIdsFromRole.length > 0
  const hasPayerAssignments = payerIdsFromRole.length > 0

  const hasOnlyPayerAssignments = !hasCustomerAssignments && hasPayerAssignments
  const hasOnlyCustomerAssignments = hasCustomerAssignments && !hasPayerAssignments
  const hasCustomerOrPayerAssignments = hasCustomerAssignments || hasPayerAssignments

  const {isLoading, isError} = rolesQueryInfo

  const rolesToGenerate = [...filteredSalesAgentRoles, ...filteredBusinessOwnerRoles]

  const shouldFetchAllCustomersOrPayersForSalesAgent =
    salesAgentRoles.length > 0 && !hasCustomerAssignments && !hasPayerAssignments

  return {
    isSalesAgent: salesAgentRoles.length > 0,
    isBusinessOwner: businessOwnerRoles.length > 0,
    shouldFetchAllCustomersOrPayersForSalesAgent,
    salesAgentDataScopes,
    hasCustomerAssignments,
    hasPayerAssignments,
    hasOnlyPayerAssignments,
    hasOnlyCustomerAssignments,
    salesAgentRoles,
    businessOwnerRoles,
    hasCustomerOrPayerAssignments,
    customerIdsFromRole,
    payerIdsFromRole,
    isRolesLoading: isLoading,
    hasRolesError: isError,
    countriesOfRoles,
    rolesToGenerate
  }
}

const getDatascopeFromRole = (roles: Role[], role: RoleType, property: string) =>
  roles
    .filter((currentRole) => currentRole.roleType === role)
    .flatMap((r) => r.dataScope[property]) || null

export const useRolesBusinessLogic = () => {
  const {data, isLoading, error} = useRoles()

  const roles = data ?? []

  return {
    roles,
    isLoading,
    error,
    isSalesAgent: hasSalesAgentRole(roles),
    isBusinessOwner: hasBusinessOwnerRole(roles),
    isBusinessOwnerCustomer: hasBusinessOwnerRoleCustomer(roles),
    isOnlyQCSupervisor: hasOnlyQCSupervisorRole(roles),
    isQCSupervisor: hasQCSupervisorRole(roles),
    getDatascopePropertyFromRole: (role: RoleType, property: string) =>
      getDatascopeFromRole(roles, role, property)
  }
}
