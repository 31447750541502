import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  EnvAuthRequestProvider,
  createHttpClient,
  getDefaultDFBackends,
  createPublicHttpClient
} from '@hconnect/apiclient'

// FIXME: these global objects should be replaced with react context and context-providers
// see https://codesandbox.io/s/github/kentcdodds/bookshelf/tree/master
// and https://kentcdodds.com/blog/authentication-in-react-applications

const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
const selectedBackend = backendSelector.getSelectedBackend()

export const loginStorage = new BrowserLoginStorage(
  `HC-${process.env.REACT_APP_CLIENT_NAME}-${selectedBackend.NAME}`
)

// Note: for production we have to use a different auth host
// Note: This is not send over as a parameter. This is directly used by the UI
// component to redirect to the matching auth host.
// authenticatorHost: SELECTED_ENV.authenticatorHost,

export const authRequestProvider = new EnvAuthRequestProvider(backendSelector)
export const loginFlow = new BrowserLoginFlow(loginStorage, authRequestProvider, backendSelector)

export const publicApi = createPublicHttpClient({
  backendSelector
})

export const api = createHttpClient({
  backendSelector,
  authRequestProvider,
  loginStorage,
  loginFlow
})

// FIXME: what about the other APIs?
// api.interceptors.response.use((response) => response)

// FIXME: set timeout?
// api.defaults.timeout = 4000;
