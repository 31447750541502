import {DecodedToken} from '@hconnect/apiclient'
import React, {useState} from 'react'

import {loginFlow} from './loginClasses'

type AuthState = 'loading' | 'authenticated' | 'unauthenticated'
type AuthContextValue = {
  authState: AuthState
  decodedToken: DecodedToken | null
  login: () => Promise<void>
  logout: () => Promise<void>
  register: (type, countryId?) => Promise<void>
}

export const AuthContext = React.createContext<AuthContextValue>({
  authState: 'loading',
  decodedToken: null,
  login: () => loginFlow.startLoginProcess(),
  logout: () => loginFlow.startLogoutProcess(),
  register: (type, countryId?) => loginFlow.startRegistrationProcess(type, countryId)
})
AuthContext.displayName = 'AuthContext'

export const AuthProvider = (props) => {
  const [authState, setAuthState] = useState<AuthState>('loading')
  const [decodedToken, setDecodedToken] = useState<DecodedToken | null>(null)

  const boot = React.useCallback(async () => {
    const loginResponse = await loginFlow.getLoginState()
    setAuthState(loginResponse.loggedIn ? 'authenticated' : 'unauthenticated')
    if (loginResponse.loggedIn) {
      setDecodedToken(loginResponse.decodedToken)
    }
  }, [])

  React.useEffect(() => {
    void boot()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        authState,
        decodedToken,
        login: () => loginFlow.startLoginProcess(),
        logout: () => loginFlow.startLogoutProcess(),
        register: (type, countryId = '') => loginFlow.startRegistrationProcess(type, countryId)
      }}
      {...props}
    />
  )
}

export const useAuth = () => {
  return React.useContext(AuthContext)
}
