import AE from './AE.svg'
import AF from './AF.svg'
import AG from './AG.svg'
import AI from './AI.svg'
import AL from './AL.svg'
import AM from './AM.svg'
import AN from './AN.svg'
import AO from './AO.svg'
import AR from './AR.svg'
import AT from './AT.svg'
import AU from './AU.svg'
import AW from './AW.svg'
import AX from './AX.svg'
import AZ from './AZ.svg'
import BA from './BA.svg'
import BB from './BB.svg'
import BD from './BD.svg'
import BE from './BE.svg'
import BF from './BF.svg'
import BG from './BG.svg'
import BH from './BH.svg'
import BI from './BI.svg'
import BJ from './BJ.svg'
import BM from './BM.svg'
import BN from './BN.svg'
import BO from './BO.svg'
import BR from './BR.svg'
import BS from './BS.svg'
import BT from './BT.svg'
import BW from './BW.svg'
import BY from './BY.svg'
import BZ from './BZ.svg'
import CA from './CA.svg'
import CAF from './CAF.svg'
import CAS from './CAS.svg'
import CD from './CD.svg'
import CEU from './CEU.svg'
import CF from './CF.svg'
import CG from './CG.svg'
import CH from './CH.svg'
import CI from './CI.svg'
import CL from './CL.svg'
import CM from './CM.svg'
import CN from './CN.svg'
import CNA from './CNA.svg'
import CO from './CO.svg'
import COC from './COC.svg'
import CR from './CR.svg'
import CSA from './CSA.svg'
import CU from './CU.svg'
import CV from './CV.svg'
import CY from './CY.svg'
import CZ from './CZ.svg'
import DE from './DE.svg'
import DJ from './DJ.svg'
import DK from './DK.svg'
import DM from './DM.svg'
import DO from './DO.svg'
import DZ from './DZ.svg'
import EC from './EC.svg'
import EE from './EE.svg'
import EG from './EG.svg'
import EN from './EN.svg'
import ER from './ER.svg'
import ES from './ES.svg'
import ET from './ET.svg'
import EU from './EU.svg'
import FI from './FI.svg'
import FJ from './FJ.svg'
import FK from './FK.svg'
import FM from './FM.svg'
import FR from './FR.svg'
import GA from './GA.svg'
import GB from './GB.svg'
import GD from './GD.svg'
import GE from './GE.svg'
import GG from './GG.svg'
import GH from './GH.svg'
import GI from './GI.svg'
import GM from './GM.svg'
import GN from './GN.svg'
import GQ from './GQ.svg'
import GR from './GR.svg'
import GT from './GT.svg'
import GW from './GW.svg'
import GY from './GY.svg'
import HK from './HK.svg'
import HN from './HN.svg'
import HR from './HR.svg'
import HT from './HT.svg'
import HU from './HU.svg'
import ID from './ID.svg'
import IE from './IE.svg'
import IL from './IL.svg'
import IM from './IM.svg'
import IN from './IN.svg'
import IQ from './IQ.svg'
import IR from './IR.svg'
import IS from './IS.svg'
import IT from './IT.svg'
import JE from './JE.svg'
import JM from './JM.svg'
import JO from './JO.svg'
import JP from './JP.svg'
import KE from './KE.svg'
import KG from './KG.svg'
import KH from './KH.svg'
import KM from './KM.svg'
import KN from './KN.svg'
import KP from './KP.svg'
import KR from './KR.svg'
import KW from './KW.svg'
import KY from './KY.svg'
import KZ from './KZ.svg'
import LA from './LA.svg'
import LB from './LB.svg'
import LC from './LC.svg'
import LI from './LI.svg'
import LK from './LK.svg'
import LR from './LR.svg'
import LS from './LS.svg'
import LT from './LT.svg'
import LU from './LU.svg'
import LV from './LV.svg'
import LY from './LY.svg'
import MA from './MA.svg'
import MC from './MC.svg'
import MD from './MD.svg'
import ME from './ME.svg'
import MG from './MG.svg'
import MK from './MK.svg'
import ML from './ML.svg'
import MM from './MM.svg'
import MN from './MN.svg'
import MO from './MO.svg'
import MR from './MR.svg'
import MS from './MS.svg'
import MT from './MT.svg'
import MU from './MU.svg'
import MV from './MV.svg'
import MW from './MW.svg'
import MX from './MX.svg'
import MY from './MY.svg'
import MZ from './MZ.svg'
import NA from './NA.svg'
import NE from './NE.svg'
import NG from './NG.svg'
import NI from './NI.svg'
import NL from './NL.svg'
import NO from './NO.svg'
import NP from './NP.svg'
import NZ from './NZ.svg'
import OM from './OM.svg'
import PA from './PA.svg'
import PE from './PE.svg'
import PF from './PF.svg'
import PG from './PG.svg'
import PH from './PH.svg'
import PK from './PK.svg'
import PL from './PL.svg'
import PR from './PR.svg'
import PT from './PT.svg'
import PW from './PW.svg'
import PY from './PY.svg'
import QA from './QA.svg'
import RO from './RO.svg'
import RS from './RS.svg'
import RU from './RU.svg'
import RW from './RW.svg'
import SA from './SA.svg'
import SB from './SB.svg'
import SC from './SC.svg'
import SD from './SD.svg'
import SE from './SE.svg'
import SG from './SG.svg'
import SH from './SH.svg'
import SI from './SI.svg'
import SK from './SK.svg'
import SL from './SL.svg'
import SM from './SM.svg'
import SN from './SN.svg'
import SO from './SO.svg'
import SR from './SR.svg'
import ST from './ST.svg'
import SV from './SV.svg'
import SY from './SY.svg'
import SZ from './SZ.svg'
import TC from './TC.svg'
import TD from './TD.svg'
import TG from './TG.svg'
import TH from './TH.svg'
import TJ from './TJ.svg'
import TL from './TL.svg'
import TM from './TM.svg'
import TN from './TN.svg'
import TO from './TO.svg'
import TR from './TR.svg'
import TT from './TT.svg'
import TW from './TW.svg'
import TZ from './TZ.svg'
import UA from './UA.svg'
import UG from './UG.svg'
import US from './US.svg'
import UY from './UY.svg'
import UZ from './UZ.svg'
import VC from './VC.svg'
import VE from './VE.svg'
import VG from './VG.svg'
import VN from './VN.svg'
import VU from './VU.svg'
import WS from './WS.svg'
import WW from './WW.svg'
import YE from './YE.svg'
import ZA from './ZA.svg'
import ZM from './ZM.svg'
import ZW from './ZW.svg'

export const svgFlagSources = {
  AE,
  AF,
  AG,
  AI,
  AL,
  AM,
  AN,
  AO,
  AR,
  AT,
  AU,
  AW,
  AX,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BM,
  BN,
  BO,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CAF,
  CAS,
  CD,
  CEU,
  CF,
  CG,
  CH,
  CI,
  CL,
  CM,
  CN,
  CNA,
  CO,
  COC,
  CR,
  CSA,
  CU,
  CV,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EN,
  ER,
  ES,
  ET,
  EU,
  FI,
  FJ,
  FK,
  FM,
  FR,
  GA,
  GB,
  GD,
  GE,
  GG,
  GH,
  GI,
  GM,
  GN,
  GQ,
  GR,
  GT,
  GW,
  GY,
  HK,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IM,
  IN,
  IQ,
  IR,
  IS,
  IT,
  JE,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KM,
  KN,
  KP,
  KR,
  KW,
  KY,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MG,
  MK,
  ML,
  MM,
  MN,
  MO,
  MR,
  MS,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NE,
  NG,
  NI,
  NL,
  NO,
  NP,
  NZ,
  OM,
  PA,
  PE,
  PF,
  PG,
  PH,
  PK,
  PL,
  PR,
  PT,
  PW,
  PY,
  QA,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SH,
  SI,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  ST,
  SV,
  SY,
  SZ,
  TC,
  TD,
  TG,
  TH,
  TJ,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TW,
  TZ,
  UA,
  UG,
  US,
  UY,
  UZ,
  VC,
  VE,
  VG,
  VN,
  VU,
  WS,
  WW,
  YE,
  ZA,
  ZM,
  ZW
}
