import {Role} from '@hconnect/apiclient'

import {api} from '../Api/Api'

export const getRequestPathData = async <T>(path: string): Promise<T> => {
  const response = await api.get(path)
  return response.data
}

export const hasOnlyQCSupervisorRole = (roles: Role[]): boolean => {
  return roles.length === 1 && !!roles.find((role) => role.roleType === 'QC_SUPERVISOR')
}

const getSalesAgentRoles = (roles: Role[]): Role[] =>
  roles.filter((role: Role) => role.roleType === 'SALES_AGENT')

export const hasSalesAgentRole = (roles: Role[]): boolean => {
  const salesAgentRoles = getSalesAgentRoles(roles)
  return salesAgentRoles.length > 0
}

const getBusinessOwnerRoles = (roles: Role[]): Role[] =>
  roles.filter((role: Role) => role.roleType === 'BUSINESS_OWNER')

export const hasBusinessOwnerRole = (roles: Role[]): boolean => {
  const businessOwnerRoles = getBusinessOwnerRoles(roles)
  return businessOwnerRoles.length > 0
}

export const hasBusinessOwnerRoleCustomer = (roles: Role[]): boolean => {
  const businessOwnerRoles = getBusinessOwnerRoles(roles)
  return businessOwnerRoles.length > 0
}

export const hasQCSupervisorRole = (roles: Role[]): boolean => {
  return roles.some((role) => role.roleType === 'QC_SUPERVISOR')
}
