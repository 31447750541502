import qs from 'query-string'
import React, {useCallback, useState} from 'react'
import {useHistory} from 'react-router-dom'

export type UseQueryParamStateReturn<T> = [T, React.Dispatch<React.SetStateAction<T>>]

export const useQueryParamState = <T,>(
  defaultValue: Record<string, unknown>
): UseQueryParamStateReturn<T> => {
  const history = useHistory()

  const defaultValueKeys = Object.keys(defaultValue)

  const parsedValues = window.location.search ? qs.parse(window.location.search) : defaultValue

  const parsedValuesKeys = Object.keys(parsedValues)

  const atLeastOneKeyExists = parsedValuesKeys.some((value) => defaultValueKeys.includes(value))

  const [value, setValue] = useState(
    atLeastOneKeyExists ? (parsedValues as T) : (defaultValue as T)
  )

  const onSetValue = useCallback(
    (newValue) => {
      setValue(newValue as T)

      const queryParams = qs.parse(window.location.search)

      history.replace({
        search: qs.stringify({...queryParams, ...newValue} as Record<string, any>)
      })
    },
    [history]
  )

  return [value, onSetValue]
}
